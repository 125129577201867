<template>
  <div class="app-container wrap">
    <div class="table-container">
      <table cellspacing="0" cellpadding="0" border="0" class="refund-list" style="width: 100%">
        <thead class="table-head">
          <tr class="line">
            <th>
              <div class="cell">商品</div>
            </th>
            <th>
              <div class="cell">商品条码</div>
            </th>
            <th>
              <div class="cell">数量</div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body" v-for="(item, index) in list" :key="index">
          <tr class="line tips-container">
            <td colspan="10" class="tips-content">
              <div class="cell">
                <span>包裹：{{item.no}}</span>
                <span>{{item.companyName}}</span>
                <span>运单号{{item.deliveryNo}}</span>
                <router-link
                  class="link"
                  :to="'/syoung/order/refund/delivery_status/'+item.companyCode+'/'+item.deliveryNo"
                >
                  <span>查看物流详情</span>
                </router-link>
              </div>
            </td>
          </tr>
          <tr class="line">
            <td class="goods-img-package">
              <div class="cell" v-for="(datas, idx) in item.commodities" :key="idx">
                <img class="table-img" :src="datas.imgUrl" />
                <p>{{datas.commodityName}}</p>
              </div>
            </td>
            <td>
              <div class="cell" v-for="(datas, idx) in item.commodities" :key="idx">
                <div>{{datas.commodityCode}}</div>
              </div>
            </td>
            <td>
              <div
                class="cell"
                v-for="(datas, idx) in item.commodities"
                :key="idx"
              >{{datas.quantity}}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="list.length === 0" class="empty">暂无数据</div>
      <div class="pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNo"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :disabled="listLoading"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getDeliveries } from '@/api/order/inquiry/list';
import pickBy from 'lodash/pickBy';
export default {
  components: {},
  data() {
    return {
      list: [],
      listLoading: true,
      pageNo: 1,
      pageSize: 10,
      total: 0
    };
  },
  computed: {
    // 过滤
    data() {
      return pickBy(this.filter, val => !!val);
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      // const listQuery = pick(this, ['pageNo', 'pageSize', 'data']);
      // if (this.createDate) {
      //   listQuery.data.createBeginDate = this.createDate[0].getTime();
      //   listQuery.data.createEndDate = this.createDate[1].getTime();
      // }
      const id = this.$route.params.id;
      getDeliveries(id)
        .then(response => {
          this.list = response.data;
        })
        .finally(() => {
          this.listLoading = false;
        });
    }
  }
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import 'src/styles/goods-table.scss';

.dialog-goods-img {
  display: block;
  width: 60px;
  height: 60px;
}
.dialog-section {
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  .title {
    font-weight: 500;
    border-bottom: 1px solid #e8e8e8;
    padding: 18px 30px;
    font-size: 16px;
    user-select: none;
  }
  .text-container {
    display: flex;
    .text {
      display: flex;
      width: 50%;
      box-sizing: border-box;
      margin-bottom: 16px;
      min-width: 334px;
      text-align: right;
      .label {
        padding-right: 16px;
        white-space: nowrap;
        display: inline-block;
        line-height: 32px;
      }
      .content {
        flex: 1 1;
      }
    }
  }
  .text {
    padding: 18px 30px;
    font-size: 14px;
    &.second {
      padding-top: 0;
    }
    &.p0 {
      padding-bottom: 0;
    }
  }
}
.dialog-footer {
  padding: 10px 20px;
}
</style>
